import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function ControlledCarousel(props) {
    console.log("props recieved", props.SlideIndex)
    const [index, setIndex] = useState(props.SlideIndex);
    console.log("state set to", index)
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={require("../Assets/Images/image 1.png")}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>SEND</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={require("../Assets/Images/image 2.png")}
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <h3>MANAGE</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={require("../Assets/Images/image 3.png")}
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h3>ONBOARD</h3>

                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default ControlledCarousel;