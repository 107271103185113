import React from 'react'
import Header from '../Components/Header'
import "../Assets/Style/Style.css"
import { useState } from 'react'
import ControlledCarousel from '../Components/ControlledCarousel'
function LandingPage() {
  const [SlideIndex, setSlideIndex] = useState(0)
  const HandleSlider = (e) =>{
    
      setSlideIndex(e)
 console.log("setting",SlideIndex)
    
    
  }
  return (
    <div>


      <div id="__next" data-reactroot className='Background_color_container'>
        <div id="portal" className="sc-16mjz74-0 ejJsiw" />
        <div className="Toastify" />
        <div className="sc-1y1pew-0">
          <div id="notification-bars" className="sc-1vfhmxf-0 bHFvkB">
            <div data-testid="layout-webline" className="sc-1svy92d-0 evzDmE">
              <div className="sc-1svy92d-1 djhkZU">
                <div className="sc-1vfhmxf-1 eFoTzJ">
                  <div className="sc-1vfhmxf-2 iWOWNC">
                    <div className="sc-1vfhmxf-3 bBKiIO"><sub><span style={{ fontSize: '16px' }}><strong><a href="https://trezor.io/accessories#bundles"><span style={{ color: '#ffffff' }}>Bundles</span></a><span style={{ color: '#ffffff' }}> are back! Save 10% and bulk up on security
                      with Twin Bundles and more.</span></strong></span></sub></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div id="nav-header" className="t7z0mu-0 gkBnKK">
            <div data-testid="layout-header" className="t7z0mu-1 jXPPbt">
              <div className="t7z0mu-2 dOoTht"><a className="sc-15suwen-1 fbedhN"><span data-testid="layout-header-logo" className="sc-15suwen-0 bhCjqj"><i data-testid="basic-icon-iconsvg-TrezorLogo" className="r4d2gj-0 bhMAsR"><svg fill="none" viewBox="0 0 161.768 40.771">
                <path className="trezor-logo-trezor" fill="currentColor" d="M24.306 9.461C24.306 4.29 19.761 0 14.228 0 8.694 0 4.148 4.292 4.148 9.46v3.025H0v21.75l14.225 6.536 14.233-6.534V12.581H24.31l-.003-3.121Zm-15.02 0c0-2.438 2.175-4.389 4.942-4.389 2.767 0 4.94 1.951 4.94 4.389v3.024H9.287V9.461Zm13.44 21.264-8.502 3.904-8.499-3.901V17.655h17v13.07z">
                </path>
                <path className="trezor-logo-text" fill="currentColor" d="M40.019 12.485h17.886v5.17h-6.127v16.678h-5.731V17.655h-6.028ZM78.46 19.8c0-4.39-3.064-7.218-7.609-7.218H60.474v21.75h5.732v-7.314h2.174l4.051 7.314h6.627l-4.842-8.094c2.07-.78 4.244-2.83 4.244-6.438zm-8.296 2.146h-3.958v-4.39h3.953c1.482 0 2.47.879 2.47 2.147 0 1.365-.988 2.243-2.47 2.243zm10.963-9.461h16.009v5.072H86.858v3.219h9.982v4.974h-9.982v3.51h10.278v5.073H81.127Zm48.125-.294c-6.719 0-11.46 4.78-11.46 11.218 0 6.437 4.839 11.22 11.46 11.22s11.562-4.779 11.562-11.217c0-6.438-4.842-11.22-11.562-11.22zm0 17.363c-3.359 0-5.633-2.536-5.633-6.14 0-3.707 2.274-6.142 5.633-6.142 3.36 0 5.732 2.537 5.732 6.141 0 3.605-2.372 6.14-5.732 6.14zm27.67-3.316c2.074-.78 4.25-2.83 4.25-6.438 0-4.39-3.064-7.218-7.61-7.218h-10.375v21.75h5.731v-7.314h2.178l4.051 7.314h6.621zm-4.052-4.292h-3.952v-4.39h3.952c1.484 0 2.471.879 2.471 2.147 0 1.365-.987 2.243-2.471 2.243zm-52.967-9.461h16.898v4.389l-9.19 12.29h9.19v5.169H99.903v-4.39l9.19-12.288h-9.19z">
                </path>
              </svg></i></span></a>

                <div className="sc-1xw741v-0 iSHIsI"><a data-testid="header-cart" href="https://trezor.io/cart">
                  <div className="sc-1xw741v-4 cZXhWn">0</div>
                </a><button className="sc-1xw741v-5 jKBhKX"><i data-testid="basic-icon-iconsvg-HamburgerMenu" className="r4d2gj-0 bhMAsR"><svg viewBox="0 0 32 32">
                  <path className="top-path" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 12h20" />
                  <path className="bottom-path" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20h20" />
                </svg></i></button></div>
                <div className="sc-1xw741v-1 gTRnYB"><a data-testid="header-cart-desktop" href="https://trezor.io/cart" className="sc-1xw741v-2 jZcgpC">
                  <div className="sc-1xw741v-3 jirQsM">Cart</div>
                  <div data-testid="header-cart-amount-desktop" className="sc-1xw741v-4 cZXhWn">0</div>
                </a></div>
              </div>
              <div data-testid="layout-header-content">
                <div className="hidden">
                  <div className="dsk1uf-0 cunNGY">
                    <div className="dsk1uf-1 chkBbw">
                      <div className="sc-1ji03cf-0 igtBa-D"><a href="https://trezor.io/compare" className="mktufp-0 EMjuq">
                        <div className="mktufp-1 jaZpIA"><i data-testid="basic-icon-iconsvg-Compare" className="r4d2gj-0 bhMAsR"><svg viewBox="0 0 32 32" fill="none">
                          <path d="M24.5 27c1.933 0 3.5-1.567 3.5-3.5S26.433 20 24.5 20 21 21.567 21 23.5s1.567 3.5 3.5 3.5ZM24.5 20v-5.0125a6.0251 6.0251 0 0 0-1.7625-4.25L18 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M18 11V6h5M7.5 12c1.933 0 3.5-1.567 3.5-3.5S9.433 5 7.5 5 4 6.567 4 8.5 5.567 12 7.5 12ZM7.5 12v5.0125a6.025 6.025 0 0 0 1.7625 4.25L14 26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M14 21v5H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg></i></div>
                        <div>
                          <p className="mktufp-2 sAWRl">Compare products</p>
                          <p className="mktufp-3 jAXgVl">See the exact differences</p>
                        </div>
                      </a><a href="https://trezor.io/accessories#" className="mktufp-0 EMjuq">
                          <div className="mktufp-1 jaZpIA"><i data-testid="basic-icon-iconsvg-Package" className="r4d2gj-0 bhMAsR"><svg viewBox="0 0 32 32" fill="none">
                            <path d="M28 22.1624V9.8374a1.0127 1.0127 0 0 0-.5125-.875l-11-6.1875a.9751.9751 0 0 0-.975 0l-11 6.1875A1.0125 1.0125 0 0 0 4 9.8374v12.325a1.0126 1.0126 0 0 0 .5125.875l11 6.1875a.9748.9748 0 0 0 .975 0l11-6.1875a1.0128 1.0128 0 0 0 .5125-.875v0Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M22.125 19.0625v-6.5L10 5.875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M27.8627 9.325 16.1127 16 4.1377 9.325M16.1125 16 16 29.35" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg></i></div>
                          <div>
                            <p className="mktufp-2 sAWRl">Accessories</p>
                            <p className="mktufp-3 jAXgVl">Cases, seed plates, cables &amp; more</p>
                          </div>
                        </a><a href="https://trezor.io/accessories#bundles" className="mktufp-0 EMjuq">
                          <div className="mktufp-1 jaZpIA"><i data-testid="basic-icon-iconsvg-Bundle" className="r4d2gj-0 bhMAsR"><svg viewBox="0 0 32 32" fill="none">
                            <path d="M28 7H4C3.44772 7 3 7.44772 3 8V11C3 11.5523 3.44772 12 4 12H28C28.5523 12 29 11.5523 29 11V8C29 7.44772 28.5523 7 28 7Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M27 12V24C27 24.2652 26.8946 24.5196 26.7071 24.7071C26.5196 24.8946 26.2652 25 26 25H6C5.73478 25 5.48043 24.8946 5.29289 24.7071C5.10536 24.5196 5 24.2652 5 24V12" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 17H19" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                          </svg></i></div>
                          <div>
                            <p className="mktufp-2 sAWRl">Bundles</p>
                            <p className="mktufp-3 jAXgVl">All you need in one pack</p>
                          </div>
                        </a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="Button1">
              Get DETA Wallet
            </button>
         
          </div>
          
          <div className="sc-1y1pew-1">
            <div data-testid="layout-webline-gray100" className="sc-1svy92d-0 eqQghH">
              <div className="sc-1svy92d-1 eUjwzM">
                <div className="pb-20 vl:pb-32">
                  <div className="sc-1926l68-3 hcvzpn">
                    <div className="sc-1926l68-2 bSQPlt">
                      <div className="sc-1926l68-0 dCBxFb"><span>Take control of your crypto</span> in the
                        DETA  app</div>
                    </div>
                    <div className="grid items-center md:flex md:gap-3">
                      <div className="relative">
                        <div className="flex h-12 mt-3 w-fit Custom-white-Btn cursor-pointer items-center rounded-xl ">
                          <a className="flex h-full font-bold items-center whitespace-nowrap rounded-l-xl pl-6 pr-3 text-white hover:bg-primaryLighter hover:text-white hover:no-underline" href="https://github.com/trezor/trezor-suite/releases/download/v23.5.2/Trezor-Suite-23.5.2-mac-arm64.dmg" download>
                            <div>Get for desktop</div>
                          </a>
                          <div className="flex h-full items-center justify-center rounded-r-xl border-l border-white border-opacity-30 pr-6 pl-3 hover:bg-primaryLighter">
                            <i data-testid="basic-icon-iconsvg-ChevronDown" className="r4d2gj-0 bhMAsR"><svg viewBox="0 0 16 16">
                              <path d="m12.667 5.333-5 5-5-5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
                            </svg></i>
                          </div>
                        </div>
                      </div>
                      <div className="ml-4 white-text md:hidden">Available for desktop as well
                      </div><a href="https://suite.trezor.io/web" className="flex no-underline h-12 items-center justify-center rounded-xl bg-gray200 px-6 py-2 hover:no-underline col-span-2 mt-3 md:col-span-1 md:mt-0"><span className="text-body font-bold text-gray700 no-underline">DETA for web</span></a>
                    </div>
                  </div>

                  {/* sliderhere */}
                  <div>
                    <ControlledCarousel SlideIndex={SlideIndex} />
                  </div>
                  <div className='Button-holder-main'>
                    <div className='Button-holder-Inner'>
                      <button className='Button-Slider-Controller' id='0' onClick={(e)=>HandleSlider(e.target.id)}>SEND</button>
                      <button className='Button-Slider-Controller' id='1' onClick={(e)=>HandleSlider(e.target.id)}>MANAGE</button>
                      <button className='Button-Slider-Controller' id='2' onClick={(e)=>HandleSlider(e.target.id)}>ONBOARD</button>
  
                    </div>
                  </div>
                  <div>
                    <div className="keen-slider mt-3 pb-22 w-full overflow-hidden py-16 px-0 lg:pt-10 xl:[mask-image:linear-gradient(90deg,transparent_0%,black_300px,black_calc(100%-300px),transparent_100%)] xl:[mask-mode:alpha] ">
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/496397.png" alt="Bitcoin" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/510249.png" alt="Ethereum" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/492623.png" alt="Tether" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/496339.png" alt="BNB" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/492512.png" alt="USD Coin" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/512872.png" alt="XRP" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/496715.png" alt="Cardano" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/509978.png" alt="Dogecoin" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/514978.png" alt="Polygon" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/514181.png" alt="Litecoin" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/496351.png" alt="Binance USD" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/492610.png" alt="Shiba Inu" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/496085.png" alt="Avalanche" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/509690.png" alt="Dai" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/492945.png" alt="Wrapped Bitcoin" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/494903.png" alt="Uniswap" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/496797.png" alt="Chainlink" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/514255.png" alt="OKB" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                      <div className="keen-slider__slide mx-3 !overflow-visible">
                        <picture data-testid="basic-image" className="rounded-full block h-9 w-9 shrink-0 transition-[cubic-bezier(.8,.20,.48,1.0)] hover:scale-[1.6] md:h-20 md:w-20">
                          <img src="https://trezor.io/content/images/coin/list/494733.png" alt="Stellar" loading="lazy" decoding="sync" className="sc-1gzkiva-0 cicIUC" />
                        </picture>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div id="chat-button" />
      </div>



    </div>

  )
}

export default LandingPage